import React from 'react';
import { hostname } from '../../utils/constants';
import SiteLayout from '../../components/layout/SiteLayout';
import { Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import loadable from '@loadable/component';
const RoContact = loadable(() => import('../../templates/homepage/ro-contact'));

const TermsOfService = ({ data }) => {
  let ldJsonHome = "Pagina principala";
  let ldJsonLevel1Route = "/termeni-si-conditii/";
  let ldJsonLevel1Name = "Termeni si conditii de utilizare";

  let navigationLdJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": hostname + "/",
          "name": ldJsonHome
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": hostname + ldJsonLevel1Route,
          "name": ldJsonLevel1Name
        }
      }
    ]
  };

  return (
    <SiteLayout relatedPages={data?.mdx?.fields?.relatedPages} lang={data?.mdx?.fields?.lang} canonical={data?.mdx?.fields?.canonical} title={data?.mdx?.frontmatter?.title} seo={{metaTitle: data?.mdx?.frontmatter?.metaTitle, metaDescription: data?.mdx?.frontmatter?.metaDescription, metaImage: data?.mdx?.frontmatter?.metaImage?.publicURL, metaCover: data?.mdx?.frontmatter?.metaCover?.publicURL, twitterCard: data?.mdx?.frontmatter?.twitterCard, ldJson: data?.mdx?.frontmatter?.ldJson, navigationLdJson: navigationLdJson, keywords: data?.mdx?.frontmatter?.metaKeywords, slug: data?.mdx?.fields?.slug.replace("/posts", "")}}>
      <div className={"w-full flex flex-col features-bg-image px-5 md:px-10 lg:px-40 xl:px-80 xxl:px-96  "}>
        <div className={"w-full"}>
          <p className={"text-right w-full text-sm text-gray-500 mt-5"}><Link to={"/"}>Pagina principala</Link> > Termeni si conditii</p>
        </div>
        <h1 className={"text-xl md:text-2xl lg:text-4xl text-center text-primary-default font-light py-20"}>{data?.mdx?.frontmatter?.title}</h1>
      </div>
      <div className={"w-full flex flex-col px-5 md:px-10 lg:px-40 xl:px-80 xxl:px-96   py-10 text-gray-500 text-sm mdx-pp-tos"}>
        <MDXRenderer>{data?.mdx?.body}</MDXRenderer>
      </div>
      <RoContact/>
    </SiteLayout>
  );
}

export default TermsOfService;
